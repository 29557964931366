.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: #fac80b; /* Change this to your desired color */
}

.swiper .swiper-button-next::after,
.swiper .swiper-button-prev::after {
  font-size: 32px; /* Adjust the size if needed */
}

.swiper .custom-pagination {
  @apply absolute bottom-2 left-0 w-full flex justify-center z-10;
}

.swiper .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.7;
}

.swiper .swiper-pagination-bullet-active {
  background: #fac80b;
  opacity: 1;
}
